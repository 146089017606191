import { Link as RouterLink, useParams } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Box, Card, Stack, Link, Alert, Tooltip, Container, Typography, Button ,Grid,Slide,makeStyles} from '@material-ui/core';
// routes
import { PATH_AUTH,PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
// layouts
import AuthLayout from '../../layouts/AuthLayout';
// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';
import AuthFirebaseSocials from '../../components/authentication/AuthFirebaseSocial';
import shoppingCartLogo from '../../assets/shopping-cart-svg.avif'
import sixpm from '../../assets/Logo/6pm.png'
import alibaba from '../../assets/Logo/alibaba-logo.png'
import amazone from '../../assets/Logo/Amazon_logo.svg.webp'
import ebay from '../../assets/Logo/ebayLogo.png'
import flipcart from '../../assets/Logo/FlipkartLogo1.png'
import walmart from '../../assets/Logo/walmart-logo.png'
import jumia from '../../assets/Logo/Jumia-Logo.jpg'
import jiji from '../../assets/Logo/Jiji.jpg'
import aliExpress from '../../assets/Logo/aliExpress.png'
import konga from '../../assets/Logo/kongaLogo.webp'
import payporte from '../../assets/Logo/payporteLogo.jpg'
import obiwezy from '../../assets/Logo/obiwezy.webp'
import shein from '../../assets/Logo/shein.png'
import BgImg from '../../assets/img/Designer1.png'
import BandH from '../../assets/Logo/bhphoto+orange.jpg'
import bestBuy from '../../assets/Logo/Best_Buy-Logo.wine.png'
import applelogo from '../../assets/Logo/Apple-Logo.jpg'
import dellLogo from '../../assets/Logo/Dell_Logo.svg.png'
import hpLogo from '../../assets/Logo/hpLogo.png'
import provantage from '../../assets/Logo/provantage-international.png'
import homeDepot from '../../assets/Logo/homeDepot.png'
import bandh from '../../assets/Logo/bandh.png'
import newAliExpress from '../../assets/Logo/aliExpress (2).png'
import amazonUk from '../../assets/Logo/amazonUk.png'

// import nigeianCoupleShopping1 from '../../assets/img/nigeianCoupleShopping1.jpg'
import nigeianCoupleShopping1 from '../../assets/img/shopping1.jpg'
import MainNavbar from 'src/layouts/main/MainNavbar';
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

// const RootStyle = styled(Page)(({ theme }) => ({
//   [theme.breakpoints.up('md')]: {
//     display: 'flex'
//   },
//   backgroundImage: `url(${BgImg})`,
//   backgroundSize: 'cover',
// }));

const RootStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center', 
  alignItems: 'center',
  [theme.breakpoints.up('md')]: {
    '& > *': {
      width: '50%',
    },
    '& > :first-child': {
      background: 'white',
    },
    '& > :last-child': {
      backgroundImage: `url(${nigeianCoupleShopping1})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      width:"100%",
      height:'100vh'
      // opacity:0.8
      // filter: 'blur(1px)'
    },
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  // maxWidth: 564,
  maxWidth: "40%",
  height:"95vh",
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
    
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 700,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  // padding: theme.spacing(12, 0),
  padding: theme.spacing(0),
  opacity: 1, 
  
 
  
}));



// ----------------------------------------------------------------------

export default function Login() {
  const { method, login } = useAuth(); 
 


  const handleLoginAuth0 = async () => {
    try {
      await login();


    } catch (error) {
      console.error(error);
    }
  };
  // const logos=[flipcart,alibaba,amazone,jumia,ebay,shein,walmart,konga,sixpm,aliExpress,obiwezy,jiji]
  const logos=[ebay,bandh,walmart,amazonUk,amazone,applelogo,dellLogo,hpLogo,newAliExpress]
     

  return (
    <RootStyle title="Login | Shop-America" >
      
      {/* <AuthLayout>
        Don’t have an account? &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to={PATH_DASHBOARD.root}>
          Get started
        </Link>
      </AuthLayout> */}

      <MHidden width="mdDown" >
        <SectionStyle >
          <Box>
          <Typography variant="h3" sx={{ px: 5, mb:5}}>
          Looks like you're new here!
            {/* Shop America */}
          </Typography>
          {/* <Typography variant="h3" sx={{ px: 5, mb: 5,color:"red" }} align='right'>
           
           From Nigeria
          </Typography> */}
          </Box>
         <Grid container spacing={3}>
          {
            logos.map((items,index)=> {

        return  <Grid item sm={4} 
                sx={{display:"flex", justifyContent:"center",height:'90px',marginTop:"10px",  
                marginTop: index === 2 ? "10px" : "0"}}>
          <img src={items} alt="login"  width={"70%"} height={"100%"} style={{objectFit:'contain'}}/>
          </Grid> 
           })
          } 
         </Grid>
         
       
        </SectionStyle>
      </MHidden>
      
      <Container maxWidth="xxl">
      
        <ContentStyle >
          <Card sx={{ backgroundColor: 'rgba(50, 50, 50, 0.85)', padding:"15px"}}>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}> 
        {/* <Typography sx={{fontSize:"large",fontWeight:"bold",position:"absolute"}} color={"#fff"}>{user==='customer' ? "Customer" : user==='staff' ? 'Staff' : 'Admin' }</Typography> */}
            <Box sx={{ flexGrow: 1 ,height:'7vh'}} >
              <Typography variant="h4" align='center' sx={{color:"#00ff37",fontWeight:"bold",fontSize:"large"}}>
                Sign into Shop America
              </Typography>
              <Typography variant="h4" align='center' sx={{color:"red"}}>
                      From Nigeria
              </Typography>
              {/* <Typography sx={{ color: '#fff',marginBottom:"20px" }}>Enter your details below.</Typography> */}
            </Box>

            {/* <Tooltip title={method}>
              <Box component="img" src={`/static/auth/ic_${method}.png`} sx={{ width: 32, height: 32 }} />
            </Tooltip> */}
          </Stack>

          {method === 'firebase' && <AuthFirebaseSocials />}
           
          {/* <Alert severity="info" sx={{ mb: 3 }} fontSize={"small"}>
            Use email : <strong>adm@shopamerica.com</strong> / password :<strong>&nbsp;123456</strong>
          </Alert> */}
          

          {method !== 'auth0' ? (
            <LoginForm />
          ) : (
            <Button sx={{backgroundColor:"#0ef03f"}} fullWidth size="large"  type="submit" variant="contained" onClick={PATH_DASHBOARD.root}>
               Login  
            </Button>
            
          )}

          {/* <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to={PATH_DASHBOARD.root}>
                Get started
              </Link>
            </Typography>
          </MHidden> */}
          </Card>
        </ContentStyle>
      </Container>
      
    </RootStyle>
  );
}
